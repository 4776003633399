import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router";
import { getData } from "../Fetch/Axios";
import URLS from "../Fetch/URLS";
import { langingPage } from "../utils/dictionary";

const CommonTable = ({
  columns,
  uri,
  reactBaseUrl,
  loading,
  scroll,
  extraParams = false,
  form,
  actionFun,
  idLikeParams,
  spreadOptions = [],
  pagination = true,
  rowSelection = false,
  goToPage = true,
  rowKey,
  setTotalCount = () => {},
  totalDocsInput,
  expandable,
  setLoaded,
}) => {
  const navigate = useNavigate();
  const dict = langingPage;
  const outlet = useOutletContext();
  const lang = outlet[1];
  const [canProceed, setCanProceed] = useState(false);

  const [details, setDetails] = useState({
    list: [],
    loading: false,
    pageLength: 0,
    totalRecords: 0,
    page: 0,
  });

  useEffect(() => {
    if (details.page >= 1 && setLoaded) {
      setLoaded(() => {
        return {
          isPage: details.page,
          randome: Math.random(),
          length: details?.list?.length,
        };
      });
    }
  }, [details]);

  const params = useParams();

  useEffect(() => {
    setCanProceed(true);
  }, []);

  const handlePageChange = (pageNumber, recs) => {
    if (isNaN(pageNumber)) {
      pageNumber = 1;
    }

    let path = "/" + reactBaseUrl + "/page=" + pageNumber + "/limit=" + recs;

    if (extraParams) {
      path = path + "/query=" + extraParams;
    }

    navigate(path);
  };

  const [localParams, setLocalParams] = useState();

  useEffect(() => {
    if (params) {
      setLocalParams(JSON.stringify(params));
    }
  }, [params]);

  useEffect(() => {
    if (canProceed) {
      const getDetails = async (extraParams) => {
        setDetails((prev) => {
          return { ...prev, loading: true };
        });

        let url = URLS[uri];
        if (idLikeParams) {
          url = url + "/" + idLikeParams;
        }

        if (!url.includes("?")) url = url + "?";

        if (params.page) {
          url = url + "&page=" + params.page.split("=")[1];
        }

        if (params.limit) {
          url = url + "&limit=" + params.limit.split("=")[1];
        }

        if (params.query) {
          url = url + params.query.split("query=")[1];
        }

        const rawDetails = await getData(url);

        if (rawDetails) {
          const details = rawDetails?.docs.map((el, index) => {
            for (const e of spreadOptions) {
              el = { ...el, ...el[e] };
            }

            let row = {
              key: el.name + index,
              ...el,
            };

            if (actionFun) {
              row.action = actionFun(row);
            }

            return row;
          });

          setTotalCount(rawDetails.pagination.totalDocs);

          setDetails(() => {
            return {
              list: details,
              loading: false,
              limit: rawDetails.pagination.limit,
              page: rawDetails.pagination.page,
              totalDocs: rawDetails.pagination.totalDocs,
            };
          });
        }
      };

      getDetails();
    }

    if (params.query) {
      const query = params.query;

      const fields = query.split("&");

      const formFields = {};

      for (let index = 1; index < fields.length; index++) {
        const spl = fields[index].split("=");

        if (spl.length === 2) {
          formFields[spl[0]] = spl[1];
        }
      }

      if (formFields.center_id) {
        let center_ids = formFields.center_id.split(",");
        console.log("center_ids----------------", center_ids);
        formFields.center_id = center_ids;
      }

      if (form) form.setFieldsValue(formFields);
    }
  }, [localParams, idLikeParams, form, canProceed]);

  const location = useLocation();

  useEffect(() => {
    if (extraParams) {
      let url = "/" + reactBaseUrl;

      if (params.page) {
        url = url + "/page=" + params.page.split("=")[1];
      } else {
        url = url + "/page=1";
      }

      if (params.limit) {
        url = url + "/limit=" + params.limit.split("=")[1];
      } else {
        if (location.pathname.includes("announcement")) {
          url = url + "/limit=10";
        } else {
          url = url + "/limit=25";
        }
      }

      url = url + "/query=" + extraParams;

      navigate(url);
    }
  }, [extraParams]);

  return (
    <div>
      <Table
        rowKey={rowKey || "_id"}
        rowSelection={rowSelection}
        loading={loading}
        columns={columns}
        expandable={expandable}
        bordered
        scroll={
          scroll || {
            x: 1600,
            y: 400,
          }
        }
        dataSource={details?.list || []}
        pagination={false}
      />

      {details?.limit && (
        <div className="mt-2 flex justify-between items-center">
          <div>
            {dict.total[lang]}: {details?.totalDocs}
          </div>

          {pagination && (
            <Pagination
              align="end"
              showSizeChanger
              showQuickJumper={pagination}
              current={details?.page || 1}
              total={totalDocsInput || details?.totalDocs || 1}
              pageSize={details?.limit || 1}
              onChange={handlePageChange}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CommonTable;
