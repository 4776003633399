import CommonDivider from "./../commonComponents/CommonDivider";
import { Button, Form } from "antd";
import { useState } from "react";
import URLS from "../Fetch/URLS";
import { downloadLostReport } from "../utils/commonFunctions";
import AntdFormItem from "./AntdFormItem";
import dayjs from "dayjs";

const getTitle = (val) => {
  return (
    <div className="flex mb-2 font-semibold m-auto text-lg w-full text-center border justify-center items-center">
      {val}
    </div>
  );
};

export default function CommonReport() {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  return (
    <div>
      <Form form={form}>
        <div>
          <CommonDivider label={"Reports"}></CommonDivider>
          <div className="grid grid-cols-3 gap-3">
            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Consolidated Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <AntdFormItem name={"cns_report"}></AntdFormItem>
                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("cns_report");
                    let qyery = "";

                    if (values?.cns_report && values?.cns_report[0]) {
                      let d1 = dayjs(values.cns_report[0]).format("YYYY-MM-DD");
                      let d2 = dayjs(values.cns_report[1]).format("YYYY-MM-DD");
                      qyery = "startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "Consolidate Report",
                      URLS.reports + qyery,
                      true
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Resolution Time Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <AntdFormItem name={"res_st_report"}></AntdFormItem>

                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("res_st_report");
                    let qyery = "";

                    if (values?.res_st_report && values?.res_st_report[0]) {
                      let d1 = dayjs(values.res_st_report[0]).format(
                        "YYYY-MM-DD"
                      );
                      let d2 = dayjs(values.res_st_report[1]).format(
                        "YYYY-MM-DD"
                      );
                      qyery = "startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "Resolution Time Report",
                      URLS.resolutionStatisticsReport + qyery
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("POS Consolidated Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <AntdFormItem name={"pos_report"}></AntdFormItem>
                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("pos_report");
                    let qyery = "";

                    if (values?.pos_report && values?.pos_report[0]) {
                      let d1 = dayjs(values.pos_report[0]).format("YYYY-MM-DD");
                      let d2 = dayjs(values.pos_report[1]).format("YYYY-MM-DD");
                      qyery = "&startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "POS Consolidate Report",
                      URLS.posReport + qyery
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("AI Utility Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <AntdFormItem name={"ai_report"}></AntdFormItem>
                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("ai_report");
                    let qyery = "";

                    if (values?.ai_report && values?.ai_report[0]) {
                      let d1 = dayjs(values.ai_report[0]).format("YYYY-MM-DD");
                      let d2 = dayjs(values.ai_report[1]).format("YYYY-MM-DD");
                      qyery = "&startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "AI Utility Report",
                      URLS.handoverReport +
                        "receiver_point_of_source=Artificial%20Intelligence" +
                        qyery
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Open and Closed Tickets Report")}
              <div className="flex gap-2 flex-col  justify-center items-center ">
                <AntdFormItem name={"oc_report"}></AntdFormItem>
                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("oc_report");
                    let qyery = "";

                    if (values?.oc_report && values?.oc_report[0]) {
                      let d1 = dayjs(values.oc_report[0]).format("YYYY-MM-DD");
                      let d2 = dayjs(values.oc_report[1]).format("YYYY-MM-DD");
                      qyery = "&startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "Open and Closed Tickets Report",
                      URLS.pendingClosed + qyery
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Lost Data Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <AntdFormItem name={"lost_report"}></AntdFormItem>
                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("lost_report");
                    let qyery = "";

                    if (values?.lost_report && values?.lost_report[0]) {
                      let d1 = dayjs(values.lost_report[0]).format(
                        "YYYY-MM-DD"
                      );
                      let d2 = dayjs(values.lost_report[1]).format(
                        "YYYY-MM-DD"
                      );
                      qyery = "&startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "Lost Report",
                      URLS.lostReports + qyery
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Found Data Report")}

              <div className="flex gap-2 flex-col  justify-center items-center ">
                <AntdFormItem name={"found_report"}></AntdFormItem>
                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("found_report");
                    let qyery = "";

                    if (values?.found_report && values?.found_report[0]) {
                      let d1 = dayjs(values.found_report[0]).format(
                        "YYYY-MM-DD"
                      );
                      let d2 = dayjs(values.found_report[1]).format(
                        "YYYY-MM-DD"
                      );
                      qyery = "&startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);

                    downloadLostReport(
                      setLoading,
                      "Found Report",
                      URLS.foundReport + qyery
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Handover Data Report")}

              <div className="flex gap-2 flex-col justify-center items-center ">
                <AntdFormItem name={"handover_report"}></AntdFormItem>
                <Button
                  onClick={() => {
                    const values = form.getFieldsValue("handover_report");
                    let qyery = "";

                    if (values?.handover_report && values?.handover_report[0]) {
                      let d1 = dayjs(values.handover_report[0]).format(
                        "YYYY-MM-DD"
                      );
                      let d2 = dayjs(values.handover_report[1]).format(
                        "YYYY-MM-DD"
                      );
                      qyery = "&startDate=" + d1 + "&endDate=" + d2;
                    }

                    setLoading(true);

                    downloadLostReport(
                      setLoading,
                      "Handover Report",
                      URLS.handoverReport + qyery
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Lost Hourly Data Report")}
              <div className="flex gap-2 flex-col justify-center items-center ">
                <Button
                  onClick={() => {
                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "Time-Based_Lost_Report",
                      URLS.itervalReports + "&isLost=Lost&"
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className="justify-center items-center border-1 border-violet-700 bg-orange-50 rounded-md p-2 shadow-md ">
              {getTitle("Found Hourly Data Report")}
              <div className="flex gap-2 flex-col justify-center items-center ">
                <Button
                  onClick={() => {
                    setLoading(true);
                    downloadLostReport(
                      setLoading,
                      "Time-Based_Found_Report",
                      URLS.itervalReports + "&isLost=Found&"
                    );
                  }}
                  loading={loading}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
