import React, { useEffect, useState } from "react";
import { Upload, Button, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { getData, patchData, postData } from "../../Fetch/Axios";
import URLS from "../../Fetch/URLS";
import dayjs from "dayjs";
import { addRandomTime } from "../../utils/commonFunctions";

function formatLicenseString(input) {
  try {
    let res = input
      .trim() // Remove leading and trailing spaces
      .replace(/\s*-\s*/g, "-") // Remove spaces around dashes
      .replace(/\s+/g, ""); // Remove all remaining spaces

    return res;
  } catch (error) {
    return { error: true, input };
  }
}

const ExcelUploaderCalling = () => {
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [isLost, setIsLost] = useState(true);
  const [foundMessage, setFoundMessage] = useState();

  const [unresolvedTicketNumbers, setunresolvedTicketNumbers] = useState({
    unsersolved: [],
    wrong: [],
    resolved: [],
    already: [],
  });

  const handleFileUpload = (file) => {
    setLoading(true);
    setunresolvedTicketNumbers(() => {
      return {
        unsersolved: [],
        wrong: [],
        resolved: [],
        already: [],
      };
    });
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const finalData = [];
      let el = jsonData[0];

      let required = ["Ticket No", "Resolution"];

      let keys = Object.keys(el);

      if (required.length !== keys.length) {
        return message.error("Incorrect Excel Format");
      }

      for (let index = 0; index < keys.length; index++) {
        if (
          required[index].toLocaleLowerCase() !==
          keys[index].toLocaleLowerCase()
        ) {
          message.error("Incorrect Excel Format");
          return;
        }
      }

      const finalIds = [];

      const unresolvedTicketNumbers = [];
      const finalResolvedCases = [];
      const incorrectTicketNumbers = [];
      const already = [];

      const complaint_numbers = [];

      for (const element of jsonData) {
        let ticketNumber = formatLicenseString(element["Ticket No"]);

        if (isLost) {
          if (!ticketNumber?.error) {
            if (ticketNumber.includes("\r\n")) {
              ticketNumber = ticketNumber.split("\r\n").join("");
            }

            if (
              element?.Resolution?.includes("Y") ||
              element?.Resolution?.includes("y")
            ) {
              const doc = await getData(
                URLS.searchLostPerson + "?complaint_number=" + ticketNumber
              );

              if (doc && doc.docs.length !== 0 && !doc.docs[0].isFound) {
                finalIds.push(doc.docs[0]);
              } else if (doc && doc.docs.length !== 0 && doc.docs[0].isFound) {
                already.push(ticketNumber);
              } else if (doc && doc.docs.length === 0) {
                incorrectTicketNumbers.push(ticketNumber);
              }
            } else {
              console.log(
                "inside else ------ ------ ------",
                ticketNumber,
                element
              );
              unresolvedTicketNumbers.push(ticketNumber);
            }
          } else {
            incorrectTicketNumbers.push(ticketNumber.input);
          }
        } else {
          complaint_numbers.push(ticketNumber);
        }
      }

      if (isLost) {
        for (const el of finalIds) {
          let date = addRandomTime(el.createdAt);

          let formattedDate = date.toISOString();
          let formData = new FormData();

          formData.append("case_resolved_at", formattedDate);
          formData.append("receipt_lang", "en");
          formData.append("receipt_required", false);

          const updateCaseResolvedAt = await patchData(
            formData,
            URLS.searchLostPerson + "/" + el?._id
          );

          let done;

          try {
            done = await postData(
              {
                complaint_number: updateCaseResolvedAt.doc.complaint_number,
                required_receipt: false,
                callingStatus: {
                  remark: "na",
                  status: "Telephonic traced",
                },
                isFound: true,
              },
              URLS.calling_status
            );

            if (done) {
              finalResolvedCases.push(done.doc.complaint_number);
            }
          } catch (error) {
            already.push(done?.docs[0]?.complaint_number);
            message.info(error.toString());
          }
        }

        setunresolvedTicketNumbers(() => {
          return {
            wrong: incorrectTicketNumbers,
            resolved: finalResolvedCases,
            unsersolved: unresolvedTicketNumbers,
            already,
          };
        });

        if (finalResolvedCases && unresolvedTicketNumbers) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        const docs = await postData(
          { complaint_numbers },
          "/found_person/update-all-case-resolved"
        );

        if (docs) {
          setFoundMessage(docs?.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    reader.onerror = (error) => {
      message.error("Error reading the Excel file!");
    };

    reader.readAsArrayBuffer(file);
    return false; // Prevent upload, as we only need to read the file
  };

  const handleResolve = async () => {
    const data = await getData("/handover/updated-case-resolved-at");

    // const data = await getData(URLS.handover + "/get-invalid-hadndover");
    // let cs = [];
    // console.log("data.docs.length", data.docs.length);
    // for (const element of data?.docs) {
    //   if (element.lostPerson[0]) {
    //     cs.push(element.lostPerson[0].complaint_number);
    //     const formData = new FormData();
    //     formData.append("isFound", true);
    //     formData.append("receipt_required", false);
    //     const data = await patchData(
    //       formData,
    //       URLS.searchLostPerson + "/" + element.lostPerson[0]._id
    //     );
    //   } else {
    //     cs.push(element.foundPerson[0].complaint_number);
    //     const formData = new FormData();
    //     formData.append("isHandover", "Yes");
    //     formData.append("receipt_required", false);
    //     const data = await patchData(
    //       formData,
    //       URLS.searchFoundPerson + "/" + element.foundPerson[0]._id
    //     );
    //   }
    // }
  };

  return (
    <div className="flex w-full mt-10 flex-col justify-center items-center">
      <div className="font-semibold text-xl mb-3">{successMessage}</div>

      <Button onClick={() => setIsLost(!isLost)}>
        {isLost ? "Lost" : "Found"}
      </Button>

      {/* <Button onClick={handleResolve}>Reolved Lost Cases</Button> */}
      <Upload
        accept=".xlsx, .xls, .csv"
        beforeUpload={handleFileUpload}
        showUploadList={false}
      >
        <Button loading={loading} icon={<UploadOutlined />}>
          Upload Excel File Calling Status
        </Button>
      </Upload>
      {isLost ? (
        <div className="mt-1 flex font-bold flex-wrap text-xl text-center">
          Already Resolved Cases {unresolvedTicketNumbers.already.length}, Total
          Resolved Cases {unresolvedTicketNumbers.resolved.length}, Total
          Unresolved Handovered cases{" "}
          {unresolvedTicketNumbers.unsersolved.length} & Wrong Ticket Numbers{" "}
          {unresolvedTicketNumbers.wrong.length}{" "}
        </div>
      ) : (
        <div className="flex w-full text-center justify-center font-bold">
          {foundMessage}
        </div>
      )}

      {/* 
      {unresolvedTicketNumbers.already?.length !== 0 && (
        <>
          <div className="mt-4 m-2 font-semibold ">Already Resolved cases:</div>
          <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
            {unresolvedTicketNumbers.already.map((el, index) => (
              <span
                className="flex border justify-center items-center"
                key={el + index}
              >
                {el},
              </span>
            ))}
          </div>
        </>
      )}

      {unresolvedTicketNumbers.resolved?.length !== 0 && (
        <>
          <div className="mt-4 m-2 font-semibold ">Resolved cases:</div>
          <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
            {unresolvedTicketNumbers.resolved.map((el, index) => (
              <span
                className="flex border justify-center items-center"
                key={el + index}
              >
                {el},
              </span>
            ))}
          </div>
        </>
      )}

      {unresolvedTicketNumbers.unsersolved?.length !== 0 && (
        <>
          <div className="mt-4 m-2 font-semibold ">Unresolved cases:</div>
          <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
            {unresolvedTicketNumbers.unsersolved.map((el, index) => (
              <span
                className="flex border justify-center items-center"
                key={el + index}
              >
                {el},
              </span>
            ))}
          </div>
        </>
      )} */}

      {unresolvedTicketNumbers.wrong?.length !== 0 && (
        <>
          <div className="mt-4 m-2 font-semibold ">Wrong Ticket Numbers:</div>
          <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
            {unresolvedTicketNumbers.wrong.map((el, index) => (
              <span
                className="flex border justify-center items-center"
                key={el + index}
              >
                {el},
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ExcelUploaderCalling;

// ------------------------------------------------------

// import React, { useEffect, useState } from "react";
// import { Upload, Button, message, Spin } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import * as XLSX from "xlsx";
// import { getData, patchData, postData } from "../../Fetch/Axios";
// import URLS from "../../Fetch/URLS";
// import dayjs from "dayjs";

// function formatLicenseString(input) {
//   try {
//     let res = input
//       .trim() // Remove leading and trailing spaces
//       .replace(/\s*-\s*/g, "-") // Remove spaces around dashes
//       .replace(/\s+/g, ""); // Remove all remaining spaces

//     return res;
//   } catch (error) {
//     return { error: true, input };
//   }
// }

// const ExcelUploaderCalling = () => {
//   const [successMessage, setSuccessMessage] = useState();
//   const [loading, setLoading] = useState(false);

//   const [unresolvedTicketNumbers, setunresolvedTicketNumbers] = useState({
//     unsersolved: [],
//     wrong: [],
//     resolved: [],
//     already: [],
//   });

//   const handleFileUpload = (file) => {
//     setLoading(true);
//     setunresolvedTicketNumbers(() => {
//       return {
//         unsersolved: [],
//         wrong: [],
//         resolved: [],
//         already: [],
//       };
//     });
//     const reader = new FileReader();

//     reader.onload = async (e) => {
//       const data = new Uint8Array(e.target.result);
//       const workbook = XLSX.read(data, { type: "array" });

//       const sheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[sheetName];

//       const jsonData = XLSX.utils.sheet_to_json(worksheet);

//       const finalData = [];
//       let el = jsonData[0];

//       let required = ["Ticket No", "Resolution"];

//       let keys = Object.keys(el);

//       if (required.length !== keys.length) {
//         return message.error("Incorrect Excel Format");
//       }

//       for (let index = 0; index < keys.length; index++) {
//         if (required[index] !== keys[index]) {
//           message.error("Incorrect Excel Format");
//           return;
//         }
//       }

//       const finalIds = [];

//       const unresolvedTicketNumbers = [];
//       const finalResolvedCases = [];
//       const incorrectTicketNumbers = [];
//       const already = [];

//       for (const element of jsonData) {
//         let ticketNumber = formatLicenseString(element["Ticket No"]);
//         console.log(ticketNumber);

//         if (!ticketNumber?.error) {
//           if (ticketNumber.includes("\r\n")) {
//             ticketNumber = ticketNumber.split("\r\n").join("");
//           }

//           if (
//             element?.Resolution?.includes("Y") ||
//             element?.Resolution?.includes("y")
//           ) {
//             const doc = await getData(
//               URLS.searchLostPerson + "?complaint_number=" + ticketNumber
//             );

//             if (doc && doc.docs.length !== 0) {
//               finalIds.push(doc.docs[0]);
//             } else if (doc && doc.docs.length !== 0 && doc.docs[0].isFound) {
//               already.push(ticketNumber);
//             } else if (doc && doc.docs.length === 0) {
//               incorrectTicketNumbers.push(ticketNumber);
//             }
//           } else {
//             unresolvedTicketNumbers.push(ticketNumber);
//           }
//         } else {
//           incorrectTicketNumbers.push(ticketNumber.input);
//         }
//       }

//       for (const el of finalIds) {
//         let date = dayjs();

//         let formattedDate = date.toISOString();
//         let formData = new FormData();

//         formData.append("case_resolved_at", formattedDate);
//         formData.append("receipt_lang", "en");
//         formData.append("receipt_required", false);

//         const updateCaseResolvedAt = await patchData(
//           formData,
//           URLS.searchLostPerson + "/" + el?._id
//         );

//         let done;

//         const receiverHandoverData = new FormData();
//         receiverHandoverData.append("receiver_handover_date", dayjs());
//         receiverHandoverData.append(
//           "complaint_number",
//           updateCaseResolvedAt.doc.complaint_number
//         );
//         receiverHandoverData.append("receiver_point_of_source", "Other");
//         receiverHandoverData.append("type", "Police Handover");
//         receiverHandoverData.append("receiver_name", "ADGP");
//         receiverHandoverData.append("receipt_lang", "en");
//         receiverHandoverData.append("receipt_required", false);

//         try {
//           done = await postData(receiverHandoverData, URLS.handover);

//           if (done) {
//             finalResolvedCases.push(done.doc.complaint_number);
//           }
//         } catch (error) {
//           already.push(done?.docs[0]?.complaint_number);
//           message.info(error.toString());
//         }
//       }

//       setunresolvedTicketNumbers(() => {
//         return {
//           wrong: incorrectTicketNumbers,
//           resolved: finalResolvedCases,
//           unsersolved: unresolvedTicketNumbers,
//           already,
//         };
//       });

//       if (finalResolvedCases && unresolvedTicketNumbers) {
//         setLoading(false);
//       } else {
//         setLoading(false);
//       }
//     };

//     reader.onerror = (error) => {
//       message.error("Error reading the Excel file!");
//     };

//     reader.readAsArrayBuffer(file);
//     return false; // Prevent upload, as we only need to read the file
//   };

//   const handleResolve = async () => {
//     const data = await getData("/handover/updated-case-resolved-at");

//     // const data = await getData(URLS.handover + "/get-invalid-hadndover");
//     // let cs = [];
//     // console.log("data.docs.length", data.docs.length);
//     // for (const element of data?.docs) {
//     //   if (element.lostPerson[0]) {
//     //     cs.push(element.lostPerson[0].complaint_number);
//     //     const formData = new FormData();
//     //     formData.append("isFound", true);
//     //     formData.append("receipt_required", false);
//     //     const data = await patchData(
//     //       formData,
//     //       URLS.searchLostPerson + "/" + element.lostPerson[0]._id
//     //     );
//     //   } else {
//     //     cs.push(element.foundPerson[0].complaint_number);
//     //     const formData = new FormData();
//     //     formData.append("isHandover", "Yes");
//     //     formData.append("receipt_required", false);
//     //     const data = await patchData(
//     //       formData,
//     //       URLS.searchFoundPerson + "/" + element.foundPerson[0]._id
//     //     );
//     //   }
//     // }
//   };

//   return (
//     <div className="flex w-full mt-10 flex-col justify-center items-center">
//       <div className="font-semibold text-xl mb-3">{successMessage}</div>

//       {/* <Button onClick={handleResolve}>Reolved Lost Cases</Button> */}
//       <Upload
//         accept=".xlsx, .xls, .csv"
//         beforeUpload={handleFileUpload}
//         showUploadList={false}
//       >
//         <Button loading={loading} icon={<UploadOutlined />}>
//           Upload Excel File Calling Status
//         </Button>
//       </Upload>

//       <div className="mt-1 flex font-bold flex-wrap text-xl text-center">
//         Already Resolved Cases {unresolvedTicketNumbers.already.length}, Total
//         Resolved Cases {unresolvedTicketNumbers.resolved.length}, Total
//         Unresolved Handovered cases {unresolvedTicketNumbers.unsersolved.length}{" "}
//         & Wrong Ticket Numbers {unresolvedTicketNumbers.wrong.length}{" "}
//       </div>
//       {/*
//       {unresolvedTicketNumbers.already?.length !== 0 && (
//         <>
//           <div className="mt-4 m-2 font-semibold ">Already Resolved cases:</div>
//           <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
//             {unresolvedTicketNumbers.already.map((el, index) => (
//               <span
//                 className="flex border justify-center items-center"
//                 key={el + index}
//               >
//                 {el},
//               </span>
//             ))}
//           </div>
//         </>
//       )}

//       {unresolvedTicketNumbers.resolved?.length !== 0 && (
//         <>
//           <div className="mt-4 m-2 font-semibold ">Resolved cases:</div>
//           <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
//             {unresolvedTicketNumbers.resolved.map((el, index) => (
//               <span
//                 className="flex border justify-center items-center"
//                 key={el + index}
//               >
//                 {el},
//               </span>
//             ))}
//           </div>
//         </>
//       )}

//       {unresolvedTicketNumbers.unsersolved?.length !== 0 && (
//         <>
//           <div className="mt-4 m-2 font-semibold ">Unresolved cases:</div>
//           <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
//             {unresolvedTicketNumbers.unsersolved.map((el, index) => (
//               <span
//                 className="flex border justify-center items-center"
//                 key={el + index}
//               >
//                 {el},
//               </span>
//             ))}
//           </div>
//         </>
//       )} */}

//       {unresolvedTicketNumbers.wrong?.length !== 0 && (
//         <>
//           <div className="mt-4 m-2 font-semibold ">Wrong Ticket Numbers:</div>
//           <div className="w-full border flex justify-center items-center gap-3 flex-wrap">
//             {unresolvedTicketNumbers.wrong.map((el, index) => (
//               <span
//                 className="flex border justify-center items-center"
//                 key={el + index}
//               >
//                 {el},
//               </span>
//             ))}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default ExcelUploaderCalling;
